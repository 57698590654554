import axios from "./BaseService";
const API_URL = process.env.REACT_APP_API_URL;

export const getProducts = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  throw new Error(
    JSON.stringify({
      error: "Usuário não autenticado",
      status: 401,
    })
  );
};

export async function updateProducts(token, newProducts) {
  const url = `${API_URL}/products`;
  const headers = { authorization: token };
  const response = await axios.patch(url, newProducts, { headers });
  return response.data;
}
