const API_URL = process.env.REACT_APP_API_URL;

export const getInflation = async () => {
    const response = await fetch(`${API_URL}/inflation`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(JSON.stringify({
            error: response.statusText,
            status: response.status
          }))
    }

    return await response.json();
};