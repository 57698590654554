const API_URL = process.env.REACT_APP_API_URL;

const getMetrics = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metrics`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  throw new Error(
    JSON.stringify({
      error: "Usuário não autenticado",
      status: 401,
    })
  );
};

const getMetricsales = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metricsales`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getMetricstock = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metricstock`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getBusiness = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/business`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getProjecteds = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/projecteds`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getStockDaysWithPercent = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/stockdayswithPercent`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getStockValues = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/stockvalues`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

export {
  getBusiness,
  getMetricsales,
  getProjecteds,
  getMetrics,
  getMetricstock,
  getStockDaysWithPercent,
  getStockValues,
};
